$blue: #0e2b4c;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

$primary: #0e2b4c;
$secondary: #5f7187;
$tertiary: #959150;
$quaternary: #0E5496;

$dark: #212529;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$active-hover: #d0d0d0;
$inactive: #0E5496;
$white: #fff;
$light: #CCCCCC;
$body-bg: #F4F6F6;
$active-tertiary: #9a9650;
$low-tertiary: #b3b296;


