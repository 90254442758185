@import "styles/colors";
@import "sweetalert2/src/variables";


$swal2-outline-color: #375981 !important;
$swal2-confirm-button-background-color: $primary !important;

@import "sweetalert2/src/sweetalert2";

.swal2-actions button{
  border-radius: 8px !important;
  width: 150px !important;
  height: 54px !important;
}

/*.swal2-question{
  background-color: $tertiary;
  color: white !important;
  border: 0 !important;
  font-size: 14px !important;
}

.swal2-success{
  background-color: $success;
  color: white !important;
  border: 0 !important;
  font-size: 14px !important;
}
*/
//.swal2-icon{
//  max-width: 30px;
//  max-height: 30px;
//}

.swal2-popup{
  border-radius: 20px !important;
  //padding: 15px 0 15px 0
}
