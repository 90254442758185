.btn-outline-white {
  border-color: $white !important;
  color: white;
}

.btn-outline-white:hover {
  border-color: $active-hover !important;
  color: $active-hover !important;
}

.btn-outline-white::after {
  color: white;
}

.btn-outline-white::after:hover {
  color: $active-hover !important;
}

.profile-btn .dropdown-toggle::after {
  content: none !important;
  border: 0 !important;
}

.btn-complementary {
  background-color: $tertiary !important;
  border-color: $tertiary !important;
  color: white !important;
}

.btn-complementary:hover {
  color: #efefef !important;
}

.color-tertiary{
  color: $tertiary !important;
}

.border-tertiary{
  border-color: $tertiary !important;
}

.btn-tertiary {
  background-color: $tertiary !important;
  color: white !important;
}

.btn-quaternary {
  background-color: $quaternary !important;
  color: white !important;
}

.tertiary-badge {
  background-color: $tertiary !important;
  color: white !important;
}

.btn-outline-tertiary {
  border-color: $tertiary !important;
  color: $tertiary !important;
}

.btn-outline-tertiary:hover {
  border-color: $active-tertiary !important;
  color: $active-tertiary !important;
}

.btn-outline-tertiary:active {
  background-color: $active-tertiary !important;
  color: $active-tertiary !important;
}

.btn-primary-force {
  background-color: $primary !important;
  color: white !important;
}
.btn-secondary-force {
  background-color: $secondary !important;
  color: white !important;
}

.text-tertiary{
  color: $tertiary !important;
}

.bg-tertiary{
  background-color: $tertiary !important;
}

.mi-clase:not(::after) {
  /* Estilos sin ::after */
}

.transparent-modal > .modal-content {
  background-color: rgba(255, 255, 255, 0);
}

.expand-modal-content > .modal-content {
  height: 100%;
}

.btn{
  font-weight: 500 !important;
}
