///* You can add global styles to this file, and also import other style files */
//@import '../assets/fonts/Tungsten/font-face.css';

@import '../../node_modules/primeng/resources/primeng.min.css';
@import '../../node_modules/primeng/resources/themes/bootstrap4-light-blue/theme.css';

@layer primeng, bootstrap, app;

@import "font-face";
@import "colors";

@layer bootstrap {
  /* Importing Bootstrap SCSS file. */
  @import '../../node_modules/bootstrap/scss/bootstrap';
}

@layer app {
  @import "building-blocks/containers";
  @import "components/index";
  @import "external-tools/index";
}

@layer app {
  :root {
    font-size: 16px;
  }
}

.vertical-separator {
  width: 1px;
  height: 100%;
  background-color: #BFBFBF;
}
