.container-page {
  padding: 3rem 2rem !important;
  width: 100%;
}

@media (min-width: 768px) {
  .container-page {
    padding: 5rem 5rem !important;
  }
}
