@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$font-family-base: 'Montserrat';
//$font-size-base: 1rem;
@layer app {
  .btn {
    // font-weight: bold !important;
  }
}

p{
  margin-bottom: 0.5rem;
}

h4 {
      font-weight: 600;
}
