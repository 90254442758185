@import "styles/colors";
@import "navbar";

.btn-primary {
  background-color: $secondary !important;
  border-color: $secondary !important;
  color: white !important;
}

.btn-primary:hover {
  filter: none !important;
  background-color: $primary !important;
  border-color: $primary !important;
  color: white !important;
}
