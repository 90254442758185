@layer app {
  hr {
    color: $primary !important;
    border-width: 0.1rem;
    opacity: 1;
  }

  .btn-active {
    color: white !important;
    background-color: $primary !important;
  }

  .btn-inactive {
    color: white !important;
    background-color: $inactive !important;
  }
}
