@import "styles/colors";

.btn-navbar {
  color: white !important;
}

.btn-navbar:hover {
  color: $active-hover !important;
}

.footer-btn{
  width: 150px;
  height: 55px;
}

.form-button{
  min-width: 100px
}
